<template>
  <div class="padding-top-10 contain_pay">
    <top :bgWhite="true"></top>
    <div class="content">
      <!-- 加载中 -->
      <div class="w100" v-if="!isInit">
        <el-skeleton :rows="12" animated />
      </div>
      <div class="lists" v-else>
        <div class="headbox">
          <div>当前订单状态:{{ orderinfo.status | status }}</div>
        </div>
        <div class="flex orderxx">
          <div class="xx-left">
            <div class="left-tit">订单信息</div>
            <div class="left-con flex">
              <div>
                <div style="margin-bottom: 20px">买家留言：暂无</div>
                <div>订单编号：{{ orderinfo.order_sn }}</div>
              </div>
              <div class="lxkf">
                联系客服 <span class="el-icon-headset"></span>
              </div>
            </div>
          </div>
          <div class="xx-right">
            <div class="right-t">
              <div style="font-size: 18px">
                <img
                  class="block-32"
                  style="position: relative; top: 3px"
                  src="../../assets/image/group.png"
                  alt=""
                />
                <span style="margin-left: 10px"
                  >订单状态：{{ orderinfo.status | status }}</span
                >
              </div>
              <div style="margin: 14px 0">
                <img
                  v-for="(item, index) in orderinfo.group_user"
                  :key="index"
                  :src="item.user_head"
                  style="width: 42px; height: 42px; border-radius: 50%"
                  alt=""
                />
              </div>
              <div style="color: #666; font-size: 12px">
                {{ orderinfo.status == 1 ? "还差" + "页即可拼成" : "拼团成功" }}
              </div>
              <!-- <div>
                <el-button
                  class="cancel"
                  v-if="orderinfo.status == 1"
                  @click="cancel"
                  type="danger"
                  >取消订单</el-button
                >
              </div> -->
            </div>
          </div>
        </div>
        <div>
          <div class="listitem">
            <div class="flex headbox2">
              <div style="flex: 3">商品信息</div>
              <div style="flex: 1; text-align: center">数量</div>
              <div style="flex: 1; text-align: center">金额</div>
              <div style="flex: 1; text-align: center">状态</div>
              <div style="flex: 2; text-align: center">操作</div>
            </div>
            <div class="ordercode">
              <span style="font-weight: bold">{{ orderinfo.create_time }}</span>
              <span style="margin-left: 20px"
                >订单编号:{{ orderinfo.order_sn }}</span
              >
            </div>
            <div class="flex item" style="align-items: center">
              <div style="flex: 3; align-items: center" class="flex goods">
                <div>
                  <img
                    class="block-86 item-img"
                    :src="
                      orderinfo.order_goods_list
                        ? orderinfo.order_goods_list[0].goods_thumb
                        : ''
                    "
                    alt=""
                  />
                </div>
                <div style="margin-left: 24px">
                  {{
                    orderinfo.order_goods_list
                      ? orderinfo.order_goods_list[0].goods_name
                      : ""
                  }}
                </div>
              </div>
              <div class="border-r">
                {{
                  orderinfo.order_goods_list
                    ? orderinfo.order_goods_list[0].num
                    : ""
                }}
              </div>
              <div class="border-r" style="color: #fd4d39">
                ￥{{
                  orderinfo.order_goods_list
                    ? orderinfo.order_goods_list[0].total_scrabble
                    : ""
                }}
              </div>
              <div class="border-r">{{ orderinfo.status | status }}</div>
              <div class="btn" style="flex: 2; text-align: center">
                <!-- <div
                  style="margin-top: 12px"
                  v-if="orderinfo.status == 1"
                  @click="cancel(orderinfo)"
                >
                  取消订单
                </div> -->
                <div style="margin-top: 12px" v-if="orderinfo.status != 1">
                  删除订单
                </div>
              </div>
            </div>
            <div class="footer">
              <div class="footertit">订单信息</div>
              <div class="flex footermain">
                <div>
                  <div>下单时间：{{ orderinfo.create_time }}</div>
                  <div style="margin-top: 14px">
                    支付时间：{{ orderinfo.pay_time }}
                  </div>
                  <div style="margin-top: 14px">
                    支付方式：{{ orderinfo.pay_type }}
                  </div>
                </div>
                <div style="text-align: right">
                  <div>商品总价：￥{{ orderinfo.order_money }}</div>
                  <div style="margin-top: 14px">
                    运费：￥{{ orderinfo.order_money }}
                  </div>
                  <div style="margin-top: 47px">
                    <span style="font-weight: bold; color: #333">实付款：</span>
                    <span style="font-weight: bold; color: #fd4d39"
                      >￥{{ orderinfo.order_money }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import top from "@/components/top";
import bottom from "@/components/bottom";
export default {
  name: "authen",
  components: {
    bottom,
    top,
  },
  data() {
    return {
      url: "", //
      timer: null,
      activeName: "0",
      orderinfo: {},
      isInit: false,
      order_sn: "",
    };
  },
  mounted() {
    this.order_sn = this.$route.query.id;
    this.loadData();
  },
  filters: {
    status(key) {
      let obj = {
        0: "未支付",
        1: "待拼成",
        2: "成团",
        3: "印刷中",
        4: "排版中",
        5: "已完成",
        "-1": "已取消",
      };
      return obj[key];
    },
  },
  methods: {
    loadData() {
      this.ajax(
        "post",
        "/v1/6381630838236",
        {
          order_sn: this.order_sn,
        },
        (res) => {
          this.isInit = true;
          this.orderinfo = res.data;
          console.log(this.orderinfo, res.data);
        }
      );
    },
    //取消订单
    cancel() {
      this.$confirm(`确定取消订单?`, "提示", {
        confirmButtonText: "好的",
        type: "info",
      })
        .then(() => {
          this.ajax(
            "post",
            "/v1/6381679e58f76",
            {
              order_sn: this.orderinfo.order_sn,
            },
            (res) => {
              this.lists = [];
              this.page = 1;
              this.loadData();
            }
          );
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.contain_pay {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow: auto;
}
.content {
  width: 69%;
  height: auto;
  margin: 80px auto;
}
.lists {
  margin-top: 129px;
}
.headbox {
  padding: 28px 40px;
  border: 1px solid #e54f42;
  font-size: 18px;
  color: #333;
  background: rgba(229, 79, 66, 0.08);
  font-weight: bold;
}
.headbox2 {
  padding: 18px 32px;
  border: 1px solid #eee;
  font-size: 14px;
  color: #333;
  background: #f9f9f9;
  margin-top: 24px;
}
.orderxx {
  justify-content: space-between;
  margin-top: 20px;
}
.ordercode {
  background: #eaf8ff;
  border: 1px solid #9ed1ff;
  padding: 12px 32px;
}
.border-r {
  flex: 1;
  text-align: center;
  height: 148px;
  border-right: 1px solid #9ed1ff;
  line-height: 148px;
}
.goods {
  border-right: 1px solid #9ed1ff;
}
.item {
  border: 1px solid #9ed1ff;
}
.footer {
  padding: 9px 40px;
  border: 1px solid #eeeeee;
  margin-top: 24px;
}
.footertit {
  padding: 16px 0;
  font-weight: bold;
  border-bottom: 1px dashed #bfbfbf;
}
.footermain {
  padding: 26px 0;
  justify-content: space-between;
}
.item-img {
  padding: 32px;
  display: inline-block;
}
.xx-left {
  width: 30%;
  margin-right: 20px;
  border: 1px solid #eee;
  height: 320px;
  position: relative;
}
.left-tit {
  padding: 12px 20px;
  background: #eaf8ff;
  border-bottom: 1px dashed #07a0f8;
  font-size: 16px;
  color: #333;
}
.left-con {
  padding: 24px 20px;
  font-size: 14px;
  color: #333;
  height: 230px;
  flex-direction: column;
  justify-content: space-between;
}
.lxkf {
  width: 120px;
  color: #07a0f8;
  padding: 5px 21px;
  text-align: center;
  border: 1px solid #d3e7ff;
  margin: 0 auto;
}
.xx-right {
  flex: 1;
  border: 1px solid #eee;
  height: 320px;
}
.right-t {
  margin: 40px 0;
  text-align: center;
}
.cancel {
  padding: 9px 27px;
  border-radius: 64px;
  margin-top: 30px;
}
.btn {
  cursor: pointer;
}
/deep/.el-input {
  width: 320px;
  height: 36px;
}
</style>
